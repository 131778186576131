import React, { useState } from "react"
import Div100vh from "react-div-100vh"
import styled, { css } from "styled-components"
import FirstPanel from "../svgs/1.svg"
import SecondPanel from "../svgs/2.svg"
import ThirdPanel from "../svgs/3.svg"
import FourthPanel from "../svgs/4.svg"
import FifthPanel from "../svgs/5.svg"
import SixthPanel from "../svgs/6.svg"
import SeventhPanel from "../svgs/7.svg"
import EigthPanel from "../svgs/8.svg"
import OriginalPanels from "../images/original.png"

import SEO from "../components/seo"

const FullHeight = styled(Div100vh)`
  overflow: hidden;
  width: 100vw;
  max-width: 100%;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  scroll-snap-type: y mandatory;
`

const PanelWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
`

const MessageWrapper = styled(PanelWrapper)`
  font-family: Helvetica, Arial, sans-serif;
  color: #aaa;
  font-size: 2rem;
`

const EmojiWrapper = styled.div`
  display: inline-block;
  margin: 0 0.75rem;
  font-size: 3rem;
`

const maxDimensions = { maxHeight: "100%", maxWidth: "100%" }

const IndexPage = () => {
  const [percentage, setPercentage] = useState(0)

  return (
    <FullHeight>
      <Container>
        <SEO title="lunar.dog" />
        <PanelWrapper>
          <img src={FirstPanel} style={maxDimensions} />
        </PanelWrapper>
        <PanelWrapper>
          <img src={SecondPanel} style={maxDimensions} />
        </PanelWrapper>
        <PanelWrapper>
          <img src={ThirdPanel} style={maxDimensions} />
        </PanelWrapper>
        <PanelWrapper>
          <img src={FourthPanel} style={maxDimensions} />
        </PanelWrapper>
        <PanelWrapper
          css={css`
            background: rgba(5, 20, 51, 1);
          `}
        >
          <img
            style={{ ...maxDimensions, filter: "invert(100%)" }}
            src={FifthPanel}
          />
        </PanelWrapper>
        <PanelWrapper
          css={css`
            background: rgba(5, 20, 51, 1);
          `}
        >
          <img
            style={{ ...maxDimensions, filter: "invert(100%)" }}
            src={SixthPanel}
          />
        </PanelWrapper>
        <PanelWrapper>
          <img src={SeventhPanel} style={maxDimensions} />
        </PanelWrapper>
        <PanelWrapper
          css={css`
            background: rgba(5, 20, 51, 1);
          `}
        >
          <img
            src={EigthPanel}
            style={{ ...maxDimensions, filter: "invert(100%)" }}
          />
        </PanelWrapper>
        <MessageWrapper>
          <EmojiWrapper> ✍️ </EmojiWrapper> with{" "}
          <EmojiWrapper> 💖 </EmojiWrapper>
          by Tina
        </MessageWrapper>
        <PanelWrapper>
          <img src={OriginalPanels} style={maxDimensions} />
        </PanelWrapper>
        <MessageWrapper>
          <EmojiWrapper> ⌨️ </EmojiWrapper> with{" "}
          <EmojiWrapper> 💖 </EmojiWrapper>
          by Scott
        </MessageWrapper>
        <MessageWrapper
          css={css`
            color: white;
            font-size: 7rem;
            background: rgba(5, 20, 51, 1);
          `}
        >
          🌕.🐶
        </MessageWrapper>
      </Container>
    </FullHeight>
  )
}

export default IndexPage
